import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import ApplicationRoutes from "./Components/Routes/ApplicationRoutes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./redux/reducers/auth";
import Snackbar from "./Components/Providers/Snackbar";
import { useUserActivityTracking } from "./globalHooks";
import api from "./Services";
import Modal from "../src/Components/UI/Modal/Modal";
import {
  DeleteIconButton,
  DeleteWrapper,
  Typorgraphy,
  CancelDeleteWrapper,
  ButtonTypo,
} from "./Components/Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/Models/DeleteModal/DeleteModel.style";
import UserTimeSvg from "./Components/Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/icons/UserTimeSvg";
import ButtonComp from "./Components/UI/Button/ButtonComp";
import { userActions } from "./redux/reducers/user";
import { Mixpanel } from "./helpers/Mixpanel";
const mixpanel = require("mixpanel-browser");

function App() {
  const dispatch = useDispatch();
  const { updateVisibility } = useUserActivityTracking();
  const timerRef = useRef(null);
  const inactivityTimerRef = useRef(null);
  const isReloadingRef = useRef(false);
  // const modalTimeoutRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const DAYS = ["Sun", "M", "T", "W", "Th", "F", "Sat"];

  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  const token = localStorage.getItem("auth");

  const navigate = useNavigate();

  // Format time utility function
  const formatTime = (date) => {
    return date?.toLocaleTimeString("en-GB", { hour12: true });
  };

  // Start session timer
  const startSessionTimer = () => {
    let loginTime = sessionStorage.getItem("loginTime");
    const now = new Date();

    if (!loginTime) {
      loginTime = now.toISOString();
      sessionStorage.setItem("loginTime", loginTime);
    }

    const loginDate = new Date(loginTime);

    timerRef.current = setInterval(() => {
      const currentTime = new Date();
      const durationInSeconds = Math.floor((currentTime - loginDate) / 1000);
      sessionStorage.setItem("sessionDuration", durationInSeconds.toString());
    }, 1000);
  };

  // Stop session timer
  const stopSessionTimer = async () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);

      const userTime =
        parseInt(sessionStorage.getItem("sessionDuration"), 10) || 0;
      const userTimeInMinutes = Math.floor(userTime / 60);

      const loginTime = sessionStorage.getItem("loginTime");
      const loginDate = new Date(loginTime);

      const now = new Date();
      const startTime = formatTime(loginDate);
      const endTime = formatTime(now);
      const day = DAYS[now.getDay()];

      const payload = {
        date: loginDate.toISOString().split("T")[0],
        startTime,
        endTime,
        sessionDuration: userTimeInMinutes,
        day,
      };

      await api.userAverageTime(payload, token);

      sessionStorage.removeItem("sessionDuration");
      sessionStorage.removeItem("loginTime");
      timerRef.current = null;
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(() => {
      showInactivityModal();
    }, 300000);
  };

  const handleUserActivity = () => {
    if (!timerRef.current) {
      startSessionTimer();
    }

    resetInactivityTimer();
  };

  const showInactivityModal = () => {
    setIsModalOpen(true);

    // modalTimeoutRef.current = setTimeout(() => {
    //   stopSessionTimer();
    //   setIsModalOpen(false);
    // }, 10000);
  };

  const handleModalResponse = (isActive) => {
    // clearTimeout(modalTimeoutRef.current);

    if (isActive) {
      resetInactivityTimer();
    } else {
      stopSessionTimer();
      setLogoutModal(true);
      setTimeout(() => {
        dispatch(authActions.loginHandler({ loggedIn: "false" }));
        dispatch(userActions.logOutHandler());
        Mixpanel.track("Logged Out");
        Mixpanel.reset(); // to handle multiple users on the same device

        localStorage.removeItem("auth");
        setLogoutModal(false);
        return navigate("/auth", { replace: true });
      }, 2000);
    }

    setIsModalOpen(false);
  };

  useEffect(() => {
    if (token) {
      dispatch(authActions.loginHandler({ token, loggedIn: true }));
    }
  }, [token]);

  useEffect(() => {
    if (isLoggedIn === true) {
      startSessionTimer();
      resetInactivityTimer(); // Start tracking inactivity
    }

    return () => {
      stopSessionTimer();
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn === true) {
      const handleMouseMove = () => {
        handleUserActivity(); // Reset inactivity timer on mouse move
      };

      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);

        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }
      };
    }
  }, [isLoggedIn]);

  // Visibility tracking
  useEffect(() => {
    const visibilityHandler = (state) => {
      const date = new Date().toISOString();
      switch (state) {
        case "visible":
          updateVisibility(true, date);
          handleUserActivity(); // Resume tracking on visibility
          isReloadingRef.current = false; // Reset the reloading flag when the page is visible
          break;
        case "hidden":
          updateVisibility(false, date);

          if (isReloadingRef.current) {
            console.info("This page is reloaded, not stopping the timer.");
          } else {
            stopSessionTimer();
            console.info("This page is hidden, stopping the timer.");
          }
          break;
        default:
          console.error({
            message:
              "Invalid params (state) provided, allowed values can be 'visible' or 'hidden'",
            state,
          });
          throw Error("Error in params");
      }
    };

    const handleVisibilityChange = () => {
      visibilityHandler(document.visibilityState);
    };

    // Listen to beforeunload event to set the reloading flag
    const handleBeforeUnload = (event) => {
      isReloadingRef.current = true;
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [updateVisibility]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIORNMENT === "production" ||
      process.env.REACT_APP_ENVIORNMENT === "stage" ||
      process.env.REACT_APP_ENVIORNMENT === "patch"
    ) {
      mixpanel.init(process.env?.REACT_APP_MIXPANEL_KEY, {
        track_pageview: true,
        debug: process.env.REACT_APP_ENVIORNMENT !== "production",
        ignore_dnt: true,
        record_sessions_percent: 100, //records 100% of all sessions
      });
    }
  }, []);

  return (
    <Snackbar>
      <ThemeProvider theme={theme}>
        {/* <BrowserRouter> */}
        <ApplicationRoutes />
        {/* </BrowserRouter> */}

        {isModalOpen && isLoggedIn && (
          <Modal
            open={isModalOpen}
            setOpen={() => {
              handleModalResponse(false);
            }}
          >
            <div>
              <DeleteWrapper>
                <DeleteIconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <UserTimeSvg />
                </DeleteIconButton>

                <Typorgraphy>
                  <strong>Are you still active?</strong>
                </Typorgraphy>

                <CancelDeleteWrapper>
                  <ButtonTypo
                    variant="text"
                    size="small"
                    onClick={() => handleModalResponse(true)}
                  >
                    Yes
                  </ButtonTypo>
                  <ButtonComp
                    size="small"
                    onClick={() => handleModalResponse(false)}
                  >
                    No
                  </ButtonComp>
                </CancelDeleteWrapper>
              </DeleteWrapper>
            </div>
          </Modal>
        )}

        {isLoggedIn && logoutModal && (
          <Modal
            open={logoutModal}
            setOpen={() => {
              setLogoutModal(false);
            }}
          >
            <div>
              <DeleteWrapper>
                <Typorgraphy>
                  <strong>Logging out...</strong>
                </Typorgraphy>
              </DeleteWrapper>
            </div>
          </Modal>
        )}
      </ThemeProvider>
    </Snackbar>
  );
}

export default App;
